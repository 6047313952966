const DEFAULT_ENV = 'local'

type ReactEnv = 'test' | 'local' | 'staging' | 'prod'

interface ProcessEnv extends NodeJS.ProcessEnv {
  REACT_APP_ENV: ReactEnv | undefined
}

export type AuthMethod = 'cognito' | 'cookie' | 'none'

interface EnvConfig {
  name: string
  sentryEnabled: boolean
  intercomEnabled: boolean
  authMethod: AuthMethod
  baseUrl: string
  oidcClientId: string
  cognitoUserPoolId?: string
  modifiedAssessmentOrgId?: string
  awsRegion?: string
  superset: {
    url: string
  }
  clientBaseUrl: string
  itestAdmin?: {
    username: string
    password?: string
  }
  itestRp?: {
    username: string
    password?: string
  }
  clientVersion: string
  clientSha?: string
  cloudwatch: {
    logGroup: string
  }
}

const {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_BUILD_TAG,
  REACT_APP_BUILD_NO,
  REACT_APP_BUILD_SHA,
} = process.env as ProcessEnv
export const FRONTEND_PORT = process.env.FRONTEND_PORT || '3001'
const BACKEND_PORT = process.env.BACKEND_PORT || '9000'
const DEFAULT_REFRESH_TOKEN_INTERVAL_MIN = 4
const SESSION_INACTIVITY_EXPIRATION_HOURS = 36
const {
  protocol = 'http:',
  hostname = 'localhost',
  port = FRONTEND_PORT,
} = 'document' in globalThis
  ? document.location
  : { protocol: undefined, hostname: undefined, port: undefined }

const ENV_CONFIG: Record<ReactEnv, EnvConfig> = {
  test: {
    name: 'test',
    sentryEnabled: false,
    intercomEnabled: false,
    authMethod: 'none',
    baseUrl: protocol + '//' + hostname + ':' + BACKEND_PORT,
    oidcClientId: '28vu758pv86p6ko7iov8u1ar4v',
    superset: {
      url: 'http://localhost:8088',
    },
    clientBaseUrl: protocol + '//' + hostname + ':' + port,
    itestAdmin: {
      username:
        process.env.ITEST_ADMIN_USERNAME ||
        '3a152b2c-c57d-472c-8216-216ab0e4d7f1',
      password: process.env.ITEST_ADMIN_PASSWORD,
    },
    itestRp: {
      username: process.env.ITEST_RP_USERNAME || 'it+rp@augusthealth.com',
      password: process.env.ITEST_RP_PASSWORD,
    },
    clientVersion: 'test+3',
    clientSha: 'sha_test',
    cloudwatch: {
      logGroup: '/aws/eks/staging/application',
    },
  },
  local: {
    name: 'local',
    sentryEnabled: false,
    intercomEnabled: false,
    authMethod: 'cookie',
    baseUrl:
      REACT_APP_API_URL || protocol + '//' + hostname + ':' + BACKEND_PORT,
    oidcClientId: '28vu758pv86p6ko7iov8u1ar4v',
    superset: {
      url: 'http://localhost:8088',
    },
    cognitoUserPoolId: 'us-west-2_QHMTtO8R5',
    awsRegion: 'us-west-2',
    clientBaseUrl: protocol + '//' + hostname + ':' + port,
    itestAdmin: {
      username:
        process.env.ITEST_ADMIN_USERNAME ||
        '3a152b2c-c57d-472c-8216-216ab0e4d7f1',
      password: process.env.ITEST_ADMIN_PASSWORD,
    },
    itestRp: {
      username: process.env.ITEST_RP_USERNAME || 'it+rp@augusthealth.com',
      password: process.env.ITEST_RP_PASSWORD,
    },
    clientVersion: REACT_APP_BUILD_NO
      ? REACT_APP_BUILD_TAG + '+' + REACT_APP_BUILD_NO
      : 'local+3',
    clientSha: REACT_APP_BUILD_SHA || 'sha_local',
    modifiedAssessmentOrgId: undefined,
    cloudwatch: {
      logGroup: '/aws/eks/local/application',
    },
  },
  staging: {
    name: 'staging',
    authMethod: 'cookie',
    sentryEnabled: true,
    intercomEnabled: false,
    baseUrl: 'https://api.staging.augusthealth.com',
    oidcClientId: '44fj4u6qqld0hgtp5p2rc83fd7',
    superset: {
      url: 'https://insights.staging.augusthealth.com',
    },
    clientBaseUrl: 'https://app.staging.augusthealth.com',
    cognitoUserPoolId: 'us-west-2_QHMTtO8R5',
    awsRegion: 'us-west-2',
    clientVersion: REACT_APP_BUILD_TAG + '+' + REACT_APP_BUILD_NO,
    clientSha: REACT_APP_BUILD_SHA,
    modifiedAssessmentOrgId: '18',
    cloudwatch: {
      logGroup: '/aws/eks/staging/application',
    },
  },
  prod: {
    name: 'production',
    authMethod: 'cognito',
    sentryEnabled: true,
    intercomEnabled: true,
    baseUrl: 'https://api.augusthealth.com',
    oidcClientId: '74bukec185qj8v7rkiqnvd3s0a',
    superset: {
      url: 'https://insights.augusthealth.com',
    },
    clientBaseUrl: 'https://app.augusthealth.com',
    cognitoUserPoolId: 'us-west-2_SE1ffC7WW',
    awsRegion: 'us-west-2',
    clientVersion: REACT_APP_BUILD_TAG + '+' + REACT_APP_BUILD_NO,
    clientSha: REACT_APP_BUILD_SHA,
    modifiedAssessmentOrgId: '86',
    cloudwatch: {
      logGroup: '/aws/eks/prod/application',
    },
  },
} as const

// set the config based on the env we are in
const config = ENV_CONFIG[REACT_APP_ENV || DEFAULT_ENV]
// try to enforce (or make more difficult) accidentally turning off sentry for any non-local environment
const localOrTestEnv = config.name === 'local' || config.name === 'test'
const sentryEnabled = !localOrTestEnv || config.sentryEnabled
export default {
  // add common config here
  // then add in the env config
  ...config,
  isCypress: 'Cypress' in globalThis ? window.Cypress : false,
  sentryEnabled,
  refreshTokenInterval: DEFAULT_REFRESH_TOKEN_INTERVAL_MIN * 1000 * 60,
  sessionInactivityExpiration:
    SESSION_INACTIVITY_EXPIRATION_HOURS * 60 * 60 * 1000,
}
export const SENTRY_DSN =
  'https://6199f47d1ec340bba2cfff7d3e718116@o511514.ingest.sentry.io/5608895'
