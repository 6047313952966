import { useTransitionTrigger } from '@mui/base/useTransition'
import {
  BaseModal,
  CloseButton,
  ModalProps,
} from '@shared/components/baseMui/Modal/Layout'
import { twx } from '@shared/utils/tailwind'

/**
 * Basic Modal Component with close button
 * Consumers must specify children
 * See `@shared/components/baseMui/Modal/Layout.tsx` for common, styled child components
 *
 * Required Props
 * @param id - unique ID of the modal
 * @param open - boolean - true if the modal is open
 * @param onClose - function to trigger when closing the modal via a background click or clicking the close button
 * @param children - contents of the modal
 *
 * Optional Props
 * @param backgroundClassName - additional classes for the modal background
 * @param contentClassName - additional classes for the div wrapping children
 * @param disableBackgroundClick - enforce close Modal with Cancel or X icon only
 * @param rest
 * @constructor
 */

export const Modal = ({
  id,
  contentClassName,
  backgroundClassName,
  disableBackgroundClick,
  onClose,
  open,
  children,
  ...rest
}: ModalProps) => {
  const backdropId = `${id}-modal-backdrop`
  const contentId = `${id}-modal-contents`
  const {
    contextValue: { requestedEnter, onExited },
  } = useTransitionTrigger(open)

  return (
    <BaseModal
      data-testid={id}
      id={backdropId}
      open={open}
      backgroundClassName={twx(
        'items-center justify-center',
        backgroundClassName
      )}
      closeModal={disableBackgroundClick ? undefined : onClose}
      requestedEnter={requestedEnter}
      onExited={onExited}
      {...rest}
    >
      <CloseButton
        onClick={onClose}
        className={twx('absolute right-4 top-4')}
      />
      <div
        id={contentId}
        className={twx(
          'relative h-fit max-h-[90vh] w-fit min-w-[30vw] max-w-[90vw] overflow-y-auto rounded-[8px] bg-background p-8',
          contentClassName
        )}
        role={'dialog'}
        aria-modal={true}
      >
        {children}
      </div>
    </BaseModal>
  )
}
