import { tw, twx } from '@shared/utils/tailwind'

export type BadgeColor =
  | 'orange'
  | 'darkOrange'
  | 'gray'
  | 'purple'
  | 'darkGray'
  | 'green'
  | 'primaryLight'
  | 'primary'
  | 'secondary-outline'
  | 'gray-06'
  | 'yellow'
export type BadgeSize = 'small' | 'mediumSmall' | 'medium' | 'large'
export type BadgeShape = 'round' | 'square'

export interface Props extends React.HTMLProps<HTMLSpanElement> {
  color: BadgeColor
  badgeSize?: BadgeSize
  shape?: BadgeShape
  className?: string
  icon?: string
}

export default function Badge({
  color,
  children,
  badgeSize = 'small',
  shape = 'round',
  className,
  icon,
  id,
  ...rest
}: Props) {
  return (
    <span
      className={twx(
        'inline-block h-min min-h-min w-max min-w-max max-w-max flex-none basis-0 font-semibold',
        {
          'rounded-[9px] px-[8px] py-[4px] text-[10px] uppercase leading-[10px] tracking-[0.02em]':
            badgeSize === 'small',
          'rounded-[12px] px-[12px] py-[4px] text-[11px] leading-[16px]':
            badgeSize === 'mediumSmall',
          'rounded-[13px] px-[12px] py-[4px] text-[13px] leading-[16px]':
            badgeSize === 'medium',
          'rounded-[16px] px-[16px] py-[8px] text-[14px] uppercase leading-[16px]':
            badgeSize === 'large',
          'rounded-[4px]': shape === 'square' && badgeSize !== 'small',
          'rounded-[2px]': shape === 'square' && badgeSize === 'small',
          'bg-warn-bg text-alert': color === 'orange',
          'bg-badge-green text-badge-text-green': color === 'green',
          'bg-alert text-white': color === 'darkOrange',
          'bg-bg-light-blue text-tags-blue': color === 'purple',
          'bg-badge text-badge-text': color === 'gray',
          'bg-secondary-07 text-white': color === 'darkGray',
          'bg-primary-light text-white': color === 'primaryLight',
          'bg-primary text-white': color === 'primary',
          'bg-gray-06 text-white': color === 'gray-06',
          'border border-yellow-09 bg-yellow-12 text-yellow-07':
            color === 'yellow',
          'border border-secondary bg-transparent text-secondary hover:bg-scrim-white hover:outline hover:outline-1 hover:outline-secondary':
            color === 'secondary-outline',
        },
        className
      )}
      id={id}
      {...rest}
    >
      {icon && <i className={tw`fa-solid fa-${icon} mr-[8px]`} />}
      {children}
    </span>
  )
}
